import { AccountBox } from "../../features/login/accountBox"

function Signup() {
    return (
        <div className="logincontainer">
            <AccountBox />
        </div>
    );
}

export default Signup;