import { AccountBox } from "../../features/login/accountBox"
import './login.css';

function Login() {
    return (
        <div className="logincontainer">
            <AccountBox />
        </div>
    );
}
  
export default Login;